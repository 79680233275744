<template>
    <div class="page-hero container-fluid">
      <div class="centered col-md-3">
        <h1>{{ site }}</h1> <br>
        <span>Strona główna / {{ site }}</span>
      </div>
    </div>
</template>

<script>
export default {
  name: "PageHero",
  props: {
    site: String
  }
}
</script>

<style scoped>

</style>