window.BASE_PATH = '';

import Vue from 'vue';
import { store } from './store';
import App from './App.vue';
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import VueNumber from 'vue-number-animation';
import { router } from './router';
import Particles from "particles.vue";
import Skeleton from 'vue-loading-skeleton';
import VueTypedJs from 'vue-typed-js'
import "./fontawesome/css/all.min.css";
import VueProgressiveImage from 'vue-progressive-image';
import VueApexCharts from 'vue-apexcharts';
import Vuelidate from 'vuelidate';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

if(process.env.NODE_ENV === 'development')
  import("./assets/styles.scss").then(() => {
    console.log();
  });

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueNumber);
Vue.use(Particles);
Vue.use(Skeleton);
Vue.use(VueTypedJs);
Vue.use(require('vue-moment'));
Vue.use(VueProgressiveImage);
Vue.use(VueApexCharts);
Vue.use(Vuelidate);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('apexchart', VueApexCharts);

Vue.component('BackToTopButton', require('./components/BackToTopButton.vue').default);
Vue.component('ScrollHeader', require('./components/ScrollHeader.vue').default);
Vue.component('Header', require('./components/Header.vue').default);
Vue.component('Footer', require('./components/Footer.vue').default);
Vue.component('PageHero', require('./components/PageHero.vue').default);
Vue.component('Counter', require('./components/Counter.vue').default);
Vue.component('ClientAvatar', require('./components/ClientAvatar.vue').default);
Vue.component('Client', require('./components/Client.vue').default);
Vue.component('Channel', require('./components/Channel.vue').default);
Vue.component('ClientTooltip', require('./components/ClientTooltip.vue').default);
Vue.component('ServerGroup', require('./components/ServerGroup.vue').default);
Vue.component('ServerGroups', require('./components/ServerGroups.vue').default);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
