<template>
    <span class="server-groups">
        <server-group v-for="group in groups" :key="group.sgid" :group="group" :withName="withName"/>
    </span>
</template>

<script>

export default {
    name: "ServerGroups",
    props: {
        groups: Array,
        withName: Boolean,
    },
}
</script>