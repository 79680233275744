import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const landingPageCfg = JSON.parse(localStorage.getItem('landingPageCfg'));

export const store = new Vuex.Store({
    state: {
        cfg: landingPageCfg
    },
    mutations: {
        setConfig(state, cfg) {
            state.cfg = cfg;
            localStorage.setItem('landingPageCfg', JSON.stringify(cfg));
        }
    },
    getters: {
        img: () => (imgPath) => {
            return imgPath;
        },
        cfg(state) {
            return state.cfg;
        },
        globalCfg(state) {
            return state.cfg.global;
        },
        landingPageCfg(state) {
            return state.cfg.landingPage;
        },
    }
})