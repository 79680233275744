<template>
    <progressive-img
        class="avatar"
        :src="prefix + '/assets/avatars/' + dbid + '.png'"
        :fallback="prefix + '/assets/fallbacks/avatar.png'"
        no-ratio
        :style="'width:' + size + 'px; height:' + size + 'px;'"
    />
</template>

<script>

export default {
    name: "ClientAvatar",
    props: {
        dbid: [Number,String],
        size: {
            type: Number,
            default: 40
        }
    },
    computed: {
        prefix() {
            return process.env.NODE_ENV !== 'development' ? '' : 'http://51.75.37.170:3000';
        }
    }
}
</script>

<style>
    .avatar {
        position: relative;
        border-radius: 50%;
        -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
        margin-right: 6px;
    }

    .avatar img {
        width: auto !important;
        height: 100% !important;
        transform: translate(-50%, -50%);
        top: 50% !important;
        left: 50% !important;
    }
</style>