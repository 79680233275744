<template>
    <div
        class="my-tooltip-inner"
        :style="!loading && client.profileSettings && client.profileSettings.background ? 'background: url(' + client.profileSettings.background + ')' : ''"
    >
        <div v-if="loading">
            <div class="spinner my-5">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>
        
        <div class="py-2" v-else-if="client.nick">
            <h4 class="mb-2">{{ client.nick }}</h4>
            <h5 v-if="client.status == 'online'" class="text-success">ONLINE</h5>
            <h5 v-else-if="client.status == 'offline'" class="text-danger">OFFLINE</h5>
            <h5 v-else class="text-primary">AWAY</h5>
            <server-groups :groups="client.serverGroups"/>
        </div>
        <div class="p-2" v-else>
            <h5>Brak informacji o kliencie</h5>
        </div>
    </div>
</template>

<script>
import MainService from "../services/main.service";

export default {
    name: "ClientTooltip",
    props: {
        dbid: Number
    },
    data() {
        return {
            loading: true,
            client: {},
            loadTimeout: null
        }
    },
    mounted() {
        this.loadTimeout = setTimeout(this.loadData, 500);
    },
    destroyed() {
        clearTimeout(this.loadTimeout);
    },
    methods: {
        loadData() {
            MainService.getShortProfile(this.dbid).then(
                response => {
                    this.client = response.data;
                    this.loading = false;
                },
                error => {
                    console.log(error);
                    this.loading = false
                }
            )
        },
    }
}
</script>

<style scoped>

</style>