import axios from 'axios';
import BasicService from './basic.service'

class MainService extends BasicService {
    getConfig() {
        return this.checkError(axios.get(this.API_URL + 'config'));
    }
    
    getClientsOnline() {
        return this.checkError(axios.get(this.API_URL + 'online'));
    }

    getNews() {
        return this.checkError(axios.get(this.API_URL + 'news'));
    }

    getPartners() {
        return this.checkError(axios.get(this.API_URL + 'partners'))
    }

    getAdmins() {
        return this.checkError(axios.get(this.API_URL + 'admins'));
    }

    getTop(type) {
        return this.checkError(axios.get(this.API_URL + 'top/' + type));
    }

    getInfo() {
        return this.checkError(axios.get(this.API_URL + 'info'));
    }

    getCounters() {
        return this.checkError(axios.get(this.API_URL + 'counters'));
    }

    getShortProfile(dbid) {
        return this.checkError(axios.get(this.BASE_URL + 'profile/short/'+dbid));
    }

    sendEmail(data) {
        return this.checkError(axios.post(this.API_URL + 'send-email', data))
    }
}

export default new MainService();