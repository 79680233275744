<template>
    <div id="app" class="wrapper">
        <div :class="[windowLoaded && !loadingConfig ? 'done' : '']" class="preloader">
            <div class="left"></div>
            <div class="right"></div>
            <div class="spinner">
                <b-spinner label="Loading..."></b-spinner>
                <h3>Ładowanie ...</h3>
            </div>
        </div>
        <div v-if="!loadingConfig && isConfig">
            <Header/>
            <div :style="[fixedFooter ? 'margin-bottom: ' + footerHeight + 'px' : '']" class="main-wrapper">
                <transition mode="out-in" name="fade1">
                    <router-view/>
                </transition>
            </div>
            <Footer ref="footer" :class="[fixedFooter ? 'footer-fixed' : '']"/>
            <back-to-top-button/>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center text-center" style="min-height: 100vh">
            <h2 class="text-danger"><i class="text-danger fa fa-exclamation-triangle"></i> Niestety nie mogliśmy pobrać configu. <br>Upewnij się, że panel jest włączony</h2>
        </div>
    </div>
</template>

<script>
import MainService from "./services/main.service";

export default {
    name: 'App',
    data() {
        return {
            windowLoaded: false,
            footerHeight: 0,
            fixedFooter: false,
            loadingConfig: true,
        }
    },
    computed: {
        isConfig() {
            return !!this.$store.getters.cfg;
        }
    },
    methods: {
        async loadConfig() {
            return new Promise((resolve, reject) => {
                MainService.getConfig().then(
                    response => {
                        this.$store.commit('setConfig', response.data)
                        this.loadingConfig = false;
                        resolve(true);
                    },
                    error => {
                        this.loadingConfig = false;
                        console.log(error)
                        reject(error);
                    }
                );
            });
        },
        observer() {
            this.footerHeight = this.$refs.footer.$el.clientHeight;
        }
    },
    async mounted() {
        window.addEventListener("load", () => {
          document.body.classList.add("loaded");
          this.windowLoaded = true
        });

        await this.loadConfig();

        const resizeObserver = new ResizeObserver(this.observer);
        resizeObserver.observe(this.$refs.footer.$el);
    }
}
</script>

<style>

</style>
