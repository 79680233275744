<template>
    <number
            ref="number"
            :format="theFormat"
            animationPaused
            :from="0"
            :to="to"
            :duration="3"
            :delay="0"
            easing="Power1.easeOut"/>
</template>

<script>
    export default {
        name: "Counter",
        props: {
          to: Number
        },
        data() {
            return {
                isIn: false
            };
        },
        methods: {
            theFormat(number) {
                return number.toFixed(0);
            },
            scrollHandler() {
                const element = this.$refs.number.$el;
                const rect = element.getBoundingClientRect();
                const newIsIn = (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );

                 if (newIsIn !== this.isIn) {
                     this.isIn = newIsIn;
                     if (newIsIn)
                         this.$refs.number.play();
                 }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.scrollHandler);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.scrollHandler);
        }
    }
</script>

<style scoped>

</style>