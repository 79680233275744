<template>
    <li class="sub-channel">
        <div
            :class="[
                current && channel.cid == current.cid ? 'active' : '',
                !clickable ? 'disabled' : '', 'ts-channel',
                'spacer-'+spacerType(channel.name)
            ]"
            @click="handleFunc(channel)"
        >
            <span v-if="spacerType(channel.name) == ''">
                <img src="/assets/icons/ch_red_sub.png" v-if="!channel.flagMaxClientsUnlimited  && channel.maxClients != -1 && (channel.maxClients == 0 || channel.clients >= channel.maxClients)">
                <img src="/assets/icons/ch_yellow_sub.png" v-else-if="channel.flagPassword == 1">
                <img src="/assets/icons/ch_green_sub.png" v-else>
            </span>

            <span class="label" v-if="clickable" :id="'name-cid-' + channel.cid" >{{ trimSpacer(channel.name) }}</span>
            <span class="label" v-else :id="'name-cid-' + channel.cid">{{ trimSpacer(channel.name) }}</span>


            <b-tooltip v-if="clickable" :target="'name-cid-' + channel.cid" placement="bottom" noninteractive>Kliknij, aby zedytować. ID kanału: {{ channel.cid }}</b-tooltip>
            <b-tooltip v-else :target="'name-cid-' + channel.cid" placement="bottom" noninteractive>ID kanału: {{ channel.cid }}</b-tooltip>

            <i v-if="handle" class="fa fa-arrows-alt handle"></i>
        </div>

        <ul v-if="(channel.children && channel.children.length) || (channel.clients && channel.clients.length)" class="teamspeak-sub-channels">
            <li class="sub-channel" v-for="client in channel.clients" :key="client.nick + client.dbid">
                <client  :client="client" withIcons/>
            </li>

            <channel
                v-for="child in channel.children"
                :key="child.cid"
                :channel="child"
                :func="func"
                :current="current"
                :handle="isParent && clickable"
                :clickable="clickable"
            />

            <li class="sub-channel" v-if="addSubChannel">
                <div class="ts-channel add-sub-channel">
                    <button class="btn btn-sm btn-primary" @click="addFunc(channel.cid)">
                        <i class="fa fa-plus"></i> <span class="label">Dodaj podkanał</span>
                    </button>
                </div>
            </li>
        </ul>
        <ul v-else class="teamspeak-sub-channels">
            <li class="sub-channel" v-if="addSubChannel">
                <div class="ts-channel add-sub-channel">
                    <button class="btn btn-sm btn-primary" @click="addFunc(channel.cid)">
                        <i class="fa fa-plus"></i> <span class="label">Dodaj podkanał</span>
                    </button>
                </div>
            </li>
        </ul>
    </li>
</template>

<script>
    import Client from "./Client";

    export default {
        name: "Channel",
        components: {
            Client
        },
        props: {
            channel: Object,
            func: Function,
            addFunc: Function,
            movedFunc: Function,
            current: Object,
            addSubChannel: Boolean,
            clickable: {
                type: Boolean,
                default: function () {
                    return true
                }
            },
            real: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            handle: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            isParent: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
        },
        computed: {
            premiumChannel() {
                return this.$store.state.auth.premiumChannel;
            },
        },
        methods: {
            getFunctionName() {
              return this.channel.function;
            },
            handleFunc(channel) {
              if (this.func)
                  this.func(channel.cid);
            },
            checkMove() {
                this.movedFunc(this.channel.cid, this.channel.children);
            },
            spacerType(name) {
                if (!this.real)
                    return '';

                if (name.substr(0, 8) == '[lspacer' && name.indexOf(']') != -1)
                    return 'l';
                else if (name.substr(0, 8) == '[cspacer' && name.indexOf(']') != -1)
                    return 'c';
                else if (name.substr(0, 8) == '[rspacer' && name.indexOf(']') != -1)
                    return 'r';
                else if (name.substr(0, 7) == '[spacer' && name.indexOf(']') != -1)
                    return 's';
                else if ((name.substr(0, 8) == '[*spacer' ||
                    name.substr(0, 9) == '[*cspacer' ||
                    name.substr(0, 9) == '[*lspacer' ||
                    name.substr(0, 9) == '[*rspacer') &&
                    name.indexOf(']') != -1)
                    return '*';
                else
                    return '';
            },
            trimSpacer(name) {
                const index = name.indexOf(']');

                if (this.spacerType(name) == '*') {
                    let newName = name.substr(index+1);

                    for(let i=0; i<10; i++)
                        newName += newName;

                    return newName;
                }

                return this.spacerType(name) != '' && this.isParent ? name.substr(index+1) : name;
            }
        }
    }
</script>

<style scoped>
    .ts-channel{
        cursor: pointer;
        padding: 2px;
    }

    .ts-channel img {
        height: 1rem;
        margin-right: 5px;
    }

    .ts-channel.active {
       background: rgba(0,255,0,0.1);
        -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    }

    .ts-channel.disabled {
        /*color: #999;*/
        cursor: default;
    }

    .ts-channel.add-sub-channel .btn{
        margin: 0;
        font-size: 0.7rem;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .handle {
        float: right;
        margin-right: 3px;
    }

    .handle.without-functional {
        margin-right: 21px;
    }

    .functional {
        float: right;
    }

    .spacer-c {
        text-align: center;
    }

    .spacer-r {
        text-align: right;
    }
</style>