<template>
    <header>
        <div ref="topBar" class="top-bar">
            <div class="mr-2">
                <a :href="'mailto://' + cfg.global.information.email"><i class="fa fa-envelope mr-1"/>
                    {{ cfg.global.information.email }}</a>
            </div>
            <div class="ml-2 d-flex flex-row align-items-center">
                <i class="fab fa-teamspeak mr-1"/> Obecnie na serwerze:
                <b-skeleton-wrapper :loading="loading" class="ml-2">
                    <template #loading>
                        <span><b-skeleton style="margin-bottom: 0" type="text" width="40px"/></span>
                    </template>

                    <span class="ml-1">{{ online }}/{{ max }}</span>

                </b-skeleton-wrapper>
            </div>
        </div>

        <div :class="[fixed ? 'visible' : '']" :style="'height: ' + navBarHeight + 'px'" class="filler"></div>

        <scroll-header ref="navBar" :fixed.sync="fixed" :threshold-in="navBarHeight + topBarHeight"
                       :threshold-out="topBarHeight">
            <b-navbar class="main-nav" toggleable="lg" type="dark">
                <b-navbar-brand :to="{name: 'home'}" href="#"><img :src="$store.getters.img('/assets/images/logo-header.png')"
                                                                   class="logo"/>
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <!-- Right aligned nav items -->

                    <b-navbar-nav>
                        <b-nav-item :to="{name: 'home'}" href="#">
                            <i class="fa fa-home"/> Strona główna
                        </b-nav-item>
                        <b-nav-item v-if="pageEnabled('administration')" :to="{name: 'administration'}" href="#">
                            <i class="fa fa-suitcase"/> Administracja
                        </b-nav-item>
                        <b-nav-item v-if="pageEnabled('top')" :to="{name: 'top'}" href="#"><i class="fa fa-trophy"/> Ranking</b-nav-item>
                        <b-nav-item v-if="pageEnabled('info')" :to="{name: 'info'}" href="#"><i class="fa fa-info"/> Informacje</b-nav-item>
                        <b-nav-item v-if="pageEnabled('contact')" :to="{name: 'contact'}" href="#"><i class="fa fa-envelope"/> Kontakt</b-nav-item>
                        <b-nav-item v-for="nav in cfg.landingPage.header.additionalNav" :key="nav.name"
                                    @click.prevent="redirect(nav.link, nav.openNewTab)"><i :class="nav.icon"/> {{ nav.name }}
                        </b-nav-item>

                        <div class="nav-buttons">
                            <b-nav-item class="button-nav" href="/panel">
                                <button class="btn primary-button">
                                    <i class="fa fa-tachometer-alt mr-2 icon"/> Panel
                                </button>
                            </b-nav-item>

                            <b-nav-item v-for="nav in cfg.landingPage.header.additionalButton" :key="nav.name"
                                        @click.prevent="redirect(nav.link, nav.openNewTab)" class="button-nav">
                                <button class="btn primary-button"><i :class="nav.icon" class="mr-2 icon"/>
                                    {{ nav.name }}
                                </button>
                            </b-nav-item>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </scroll-header>
    </header>
</template>

<script>
import MainService from "../services/main.service";

export default {
    name: "Header",
    data() {
        return {
            fixed: false,
            navBarHeight: 0,
            topBarHeight: 0,
            loading: true,
            online: 0,
            max: 0,
        };
    },
    computed: {
        cfg() {
            return this.$store.getters.cfg;
        }
    },
    methods: {
        refresh() {
            this.loading = true;

            MainService.getClientsOnline().then(
                response => {
                    this.online = response.data.online;
                    this.max = response.data.max;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log(error)
                }
            );
        },
        observer() {
            this.topBarHeight = this.$refs.topBar.clientHeight;
            this.navBarHeight = this.$refs.navBar.$el.clientHeight;
        },
        redirect(url, isBlank) {
            if (isBlank)
                window.open(url, '_blank');
            else
                window.open(url, '_self');
        },
        pageEnabled(key) {
            return this.cfg.global.enabledPages.landingPage[key] ?? true;
        }
    },
    mounted() {
        const resizeObserver = new ResizeObserver(this.observer);
        resizeObserver.observe(this.$refs.topBar);
        resizeObserver.observe(this.$refs.navBar.$el);
        this.refresh();
    },
}
</script>

<style lang="scss" scoped>
.filler {
    display: none;
}

.filler.visible {
    display: block;
}
</style>