<template>
    <div class="client" :class="[center ? 'justify-content-center' : 'justify-content-start max-width']" :id="'client-' + hash">
        <client-avatar v-if="withAvatar" :dbid="client.dbid"/>
        <div class="client-teamspeak-icon" v-if="withIcons">
            <img v-if="!client.outputHardware" src="/assets/icons/hardware_output_muted.png"/>
            <img v-else-if="client.outputMuted" src="/assets/icons/output_muted.png"/>
            <img v-else-if="!client.inputHardware" src="/assets/icons/hardware_input_muted.png"/>
            <img v-else-if="client.inputMuted" src="/assets/icons/input_muted.png"/>
            <img v-else-if="client.flagTalking" src="/assets/icons/client_on.png"/>
            <img v-else src="/assets/icons/client_off.png"/>
        </div>

        <b-tooltip
            v-if="tooltip"
            custom-class="client-tooltip"
            :target="'client-' + hash"
            placement="bottom"
            :delay="{ show: 200, hide: 0 }"
            boundary="window"
        >
            <client-tooltip :dbid="client.dbid"/>
        </b-tooltip>


        <span @click="visitProfile" class="click-profile" v-if="tooltip">
            <span>{{ trimNick(client.nick) }} <small v-if="withDetails">( Dbid: {{ client.dbid }} )</small></span>
        </span>
        <span v-else >{{ trimNick(client.nick) }} <small v-if="withDetails">( Dbid: {{ client.dbid }} )</small></span>

        <server-groups v-if="withGroups" :groups="client.serverGroups"/>
    </div>
</template>

<script>

import ClientAvatar from "./ClientAvatar";

export default {
    name: "Client",
    components: {ClientAvatar},
    props: {
        client: Object,
        withAvatar: {
            type: Boolean,
            default: false
        },
        withGroups: {
            type: Boolean,
            default: false
        },
        withIcons: {
            type: Boolean,
            default: false
        },
        withDetails: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: Boolean,
            default: true
        },
        center: {
            type: Boolean,
            default: false
        },
        trim: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hash() {
            return (this.client.nick + Math.random()).split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
        }
    },
    methods: {
        visitProfile() {
            window.location = "/panel/profile/" + this.client.dbid;
        },
        trimNick() {
            return this.trim ?
                (this.client.nick.length > 14 ? this.client.nick.substr(0, 14) + '...' : this.client.nick) :
                this.client.nick
        }
    }
}
</script>

<style lang="scss">
    .client {
        display: flex;
        align-items: center;

        .client-teamspeak-icon {
            display: inline-block;
            margin-right: 5px;

            img {
                height: 1rem;
            }
        }
    }

    .client.max-width {
        width: max-content;
    }
    
    .click-profile {
        cursor: pointer;
    }
</style>