import Vue from 'vue';
import Router from 'vue-router';
import {store} from "./store";

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    base: '/',
    linkExactActiveClass: "active", // active class for *exact* links.
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                title: 'Strona główna',
            },
            component: () => import('./views/Home.vue'),
        },
        {
            path: '/administration',
            name: 'administration',
            meta: {
                title: 'Administracja',
                enabledPage: 'administration'
            },
            component: () => import('./views/Administration.vue'),
        },
        {
            path: '/top',
            name: 'top',
            meta: {
                title: 'Ranking',
                enabledPage: 'top'
            },
            component: () => import('./views/Top.vue'),
        },
        {
            path: '/info',
            name: 'info',
            meta: {
                title: 'Informacje',
                enabledPage: 'info'
            },
            component: () => import('./views/Info.vue'),
        },
        {
            path: '/contact',
            name: 'contact',
            meta: {
                title: 'Kontakt',
                enabledPage: 'contact'
            },
            component: () => import('./views/Contact.vue'),
        },
        {
            path: '*',
            name: 'error404',
            meta: {
                title: 'Error 404',
            },
            component: () => import('./views/Error404.vue'),
        },
    ]
});

router.beforeEach((to, from, next) => {
    const cfg = store.getters.cfg;

    if (to.meta) {
        document.title = (cfg?.global?.information?.serverName ?? "Strona") + " » " + to.meta.title;

        if (to.meta.enabledPage) {
            const isEnabled = cfg?.global?.enabledPages?.landingPage[to.meta.enabledPage] ?? true;

            if (!isEnabled) {
                return router.push({name: 'error404'});
            }
        }
    }

    return next();
});