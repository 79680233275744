<template>
    <footer>
        <div class="footer-info container-fluid">
            <div class="row">
                <div class="offset-xl-1 col-xl-3 col-sm-6 order-sm-1 col-12 col-info">
                    <h3><i class="fa fa-info mr-1"/> Informacje</h3>
                    <div class="content">
                        <p v-html="cfg.landingPage.footer.information"></p>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-12 order-sm-3 col-12 col-info">
                </div>
                <div class="col-xl-3 order-xl-3 col-sm-6 order-sm-2 col-12 col-info" v-if="cfg.landingPage.footer.facebookLink != ''">
                    <h3><i class="fa fa-eye mr-1"/> Obserwuj nas</h3>
                    <div class="content" style="overflow: auto">
                        <div id="fb-root"></div>
                        <div :data-href="cfg.landingPage.footer.facebookLink" class="fb-page" data-adapt-container-width="true"
                             data-hide-cover="false" data-show-facepile="true" data-small-header="false">
                            <div class="fb-xfbml-parse-ignore">
                                <blockquote :cite="cfg.landingPage.footer.facebookLink"><a
                                    :href="cfg.landingPage.footer.facebookLink"
                                    v-html="cfg.global.information.serverName"></a></blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img :src="$store.getters.img('/assets/svg/wave.svg')" class="wave"/>
        <div class="copyrights container-fluid">
            <span>© {{ new Date().getFullYear() }} {{ cfg.global.information.serverName }}</span>
            <span>Made by <a class="primary-link" href="http://ro-bot.pl" target="_blank">RO-BOT</a></span>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    computed: {
        cfg() {
            return this.$store.getters.cfg;
        }
    },
    mounted() {
        this.$nextTick(() => {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v2.4&appId=244261725605652";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        });
    }
}
</script>

<style lang="scss" scoped>
</style>