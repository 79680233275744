export default class BasicService {
    constructor() {
        let url = process.env.NODE_ENV !== 'development' ? document.location.origin : 'http://51.75.37.170:3000';
        this.API_URL = url + '/api/landing-page/';
        this.BASE_URL = url + '/api/';
    }

    checkError(result) {
        return new Promise((resolve, reject) => {
            result.then(
                response => {
                    resolve(response);
                },
                error => {
                    let err = "Undefined error";

                    if (error.response && error.response.data && error.response.data.message)
                        err = error.response.data.message;
                    else if (error.message)
                        err = error.message;
                    else if (error.toString())
                        err = error.toString();
                    
                    console.log(err);

                    reject(error);
                },
            )
        });
    }
}