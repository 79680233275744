<template>
    <span class="server-group" :class="withName ? 'with-name' : ''" :id="'group-' + hash">
        <b-tooltip :target="'group-' + hash" placement="bottom" noninteractive>
            <span v-if="withName">ID grupy: {{ group.sgid }}</span>
            <span v-else>{{ group.name }} ({{ group.sgid }})</span>
        </b-tooltip>
        <progressive-img
            class="server-group-icon"
            :src="prefix + '/assets/groupIcons/' + group.sgid + '.png'"
            :fallback="prefix + '/assets/fallbacks/group.png'"
            no-ratio
        />
        <span v-if="withName">{{ group.name }}</span>
    </span>
</template>

<script>

export default {
    name: "ServerGroup",
    props: {
        group: Object,
        withName: Boolean,
    },
    computed: {
        hash() {
            return (this.group.sgid + Math.random()).split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
        },
            prefix() {
                return process.env.NODE_ENV !== 'development' ? '' : 'http://51.75.37.170:3000';
            }
    }
}
</script>

<style>
    .server-group.with-name {
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .server-group-icon {
        position: relative;
        margin-right: 6px;
        max-width: 24px !important;
        width: 24px !important;
        height: 24px !important;
    }

    .server-group-icon img {
        top: 0;
        left: 0;
        width: 24px !important;
    }
</style>